import React from 'react'
import { graphql } from 'gatsby'
import initDashTree from './dashutils/initDashTree.js'
import getCardNode from './dashutils/getCardNode.js'
import addCardNode from './dashutils/addCardNode.js'
import * as dashNodeComponents from './dashNodeComponents'
import EntityInfoComponent from './cardComponents/EntityInfoComponent'
import ListLinksComponent from './cardComponents/ListLinksComponent'
import OccupationInfoComponent from './cardComponents/OccupationInfoComponent'
import ClassInfoComponent from './cardComponents/ClassInfoComponent'
import ClassLiteralsComponent from './cardComponents/ClassLiteralsComponent'
import TodoComponent from './cardComponents/TodoComponent.js'
import ObjectMermaidComponent from './cardComponents/ObjectMermaidComponent.js'
import ObjectPropertiesComponent from './cardComponents/ObjectPropertiesComponent.js'
import ObjectMoreSpecificTypesComponent from './cardComponents/ObjectMoreSpecificTypesComponent.js'
import ObjectValueOfPropertiesComponent from './cardComponents/ObjectValueOfPropertiesComponent.js'
import Seo from '../seo.js'
import DashHeart from '../dashibiden/DashHeart.js'
import ConceptLiteralsComponent from './cardComponents/ConceptLiteralsComponent.js'
import getEntityLabel from '../../EntityUtils/getEntityLabel.js'
import RefineExampleComponent from './cardComponents/refine/RefineExampleComponent.js'


const components = {
  // generic dashTree properties
  ...dashNodeComponents,
  // generic components
  'EntityInfoComponent': EntityInfoComponent,
  'ListLinksComponent': ListLinksComponent,
  // for esco :
  'OccupationInfoComponent': OccupationInfoComponent,

  // utils
  'TodoComponent': TodoComponent,

  // for rdfs classes
  'ClassInfoComponent': ClassInfoComponent,
  'ClassLiteralsComponent': ClassLiteralsComponent,
  'ObjectMermaidComponent': ObjectMermaidComponent,
  'ObjectPropertiesComponent': ObjectPropertiesComponent,
  'ObjectMoreSpecificTypesComponent': ObjectMoreSpecificTypesComponent,
  'ObjectValueOfPropertiesComponent': ObjectValueOfPropertiesComponent,

  // for skos classes:
  // 'ConceptSchemeLiteralsComponent': ConceptSchemeLiteralsComponent,
  'ConceptLiteralsComponent': ConceptLiteralsComponent,

  // refine intégration Test: 
  'RefineExampleComponent': RefineExampleComponent,

}

export const query = graphql`
query conceptQuery($id: String) {

  entity: skosConcept(id: {eq: $id}) {
    id
    prefLabel {
      _language
      _value
    }
  }

}
`
// @TODO : review it with the more recent `DashTreeLayout7` structure
// @TODO : rename as skosConcept
export default function DashLayoutConcept(props) {

  const dashTree = initDashTree()

  // @TODO: see exactly what append here and make clean
  const { entity } = props.data
  const { pageData } = props.pageContext
  pageData.entity = entity

  const entityName = getEntityLabel(entity)
  const classLitCard = getCardNode(pageData, 'ConceptLiteralsComponent', { 
    cardName: entityName, columnSize: 4,
    showContribution: true,
    // showEdit: true, 
  })
  addCardNode(dashTree, classLitCard, 0, 0)

  // in place editor
  // const editorName = 'Entity Editor (work in progress)'
  // const entityEditor = getCardNode(pageData, 'RefineExampleComponent', { cardName: editorName, columnSize: 8, })
  // addCardNode(dashTree, entityEditor, 1, 0)


  // const todoExamples = { contentText: '1/ Affichage d\'un graphique ? \n 2/ Ajout d\'une carte de "properties that use" ? \n 3/ Ajout de métrique d\'usages ?  ', ...pageData }
  // const todoE = getCardNode(todoExamples, 'TodoComponent', { cardName: 'Améliorations', columnSize: 4, })
  // addCardNode(dashTree, todoE, 2, 1)

  return (
    <>
      <Seo title={entityName} />
      <DashHeart dashTree={dashTree} components={components} />
    </>
  );
}
